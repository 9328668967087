import { getOrganization, getUser } from "../../../auth/authutils";
import { postFinalCertificateAttributesToIpfs } from "../../../ipfs/storenfra";
import { assetApi } from "../../../store/is/assetapi";
import { interventionApi } from "../../../store/is/interventionapi";
import { methodsApi } from "../../../store/is/methodsapi";
import { assignmentApi } from "../../../store/is/assignmentapi";
import store from "../../../store/store";

const getAssetDetails = async (assetid) => {
  const { data: assetDetails, isLoading } = await store.dispatch(
    assetApi.endpoints.getAsset.initiate(assetid)
  );
  if (!isLoading) {
    return assetDetails
      ? assetDetails?.data[0]
        ? assetDetails?.data[0]
        : null
      : null;
  }
};

const getAssetsByIds = async (assetids) => {
  var assetIdsString = assetids.join(",");
  const { data: assetDetails, isLoading } = await store.dispatch(
    assetApi.endpoints.getAssetsByIds.initiate({ assetids: assetIdsString })
  );
  if (!isLoading) {
    return assetDetails
      ? assetDetails?.data
        ? assetDetails?.data
        : null
      : null;
  }
};

async function makeAvailableForQualityVerifier(
  createYieldsActionInBC,
  createAssetsActionInBC,
  intervention,
  setSpinningYield,
  inputYields,
  addAssignmentBatch,
  setIsMakeAvailableForQualitySelected,
  qualityMatchedAssigneeItem
) {
  try {
    setSpinningYield(true);

    const assetIdsList = [];
    for (var i = 0; i < inputYields?.length; i++) {
      assetIdsList.push(inputYields[i]?.asset_id);
    }
    const assetsList = await getAssetsByIds(assetIdsList);

    if (inputYields && inputYields?.length === 0) return;

    //loop through Yields and get all the assets which is not sent to blockchain
    let tobcassets = [];
    for (var i = 0; i < assetsList?.length; i++) {
      const asset = assetsList[i];
      if (
        asset?.is_updated_to_blockchain === null ||
        asset?.is_updated_to_blockchain === false
      ) {
        tobcassets.push(asset);
      }
    }

    //Push the assets to bc
    if (tobcassets?.length > 0) {
      const payload = {
        assets: tobcassets,
        intervention: intervention,
      };
      const result = await createAssetsActionInBC(payload);
      if (!result) {
        return;
      }
    }

    //push the yields to bc
    const payloadYields = {
      yields: inputYields,
    };
    const resultYields = await createYieldsActionInBC(payloadYields);
    if (!resultYields) {
      return;
    }
    let assignmentsToUpdate = [];
    for (var i = 0; i < inputYields?.length; i++) {
      const yieldAssignment = {
        assignee_type: "Verifier",
        user_id: qualityMatchedAssigneeItem?.user_id,
        intervention_id: qualityMatchedAssigneeItem?.intervention_id,
        asset_id: inputYields[i]?.asset_id,
        yield_id: inputYields[i]?._id,
        organization: qualityMatchedAssigneeItem?.organization,
      };
      assignmentsToUpdate.push(yieldAssignment);
    }
    const batchSize = 100;
    for (let i = 0; i < assignmentsToUpdate.length; i += batchSize) {
      const batchAssignments = assignmentsToUpdate.slice(i, i + batchSize);
      await addAssignmentBatch(batchAssignments);
    }

    setIsMakeAvailableForQualitySelected(true);
  } catch {
  } finally {
    setSpinningYield(false);
  }
}

async function createEntitlementRecordsUtil(
  inputYields,
  entitlementRecordDetails,
  projectSponsorOrgId,
  flag,
  totalPreCredits,
  sponsorPreCreditsAvailability,
  setSpinningYield,
  createEntitlementRecordsActionInBC,
  updateYieldBatch,
  getPreEntitlementRecords,
  postUpdateCreateEntitilementRecords,
  userid,
  refetchYields
) {
  try {
    setSpinningYield(true);
    //If blockchain fails returnTrs

    const defaultSponsorAddress = "0x0000000000000000000000000000000000000000";

    const yieldDetailsToBc = [];
    for (let i = 0; i < inputYields?.length; i++) {
      let yieldDetails = { ...inputYields[i] };
      const safeguardDifference =
        yieldDetails?.co2_realized - yieldDetails?.co2_estimated;
      const safeguardType =
        safeguardDifference > 0
          ? "over safeguard"
          : safeguardDifference < 0
          ? "under safeguard"
          : "equal";
      const safeguardRatio = Math.abs(safeguardDifference);
      const sponsorPercentage =
        yieldDetails?.co2_realized * (totalPreCredits / 100);
      const developerPercentage =
        yieldDetails?.co2_realized * ((100 - totalPreCredits) / 100);

      const entitlementPayload = {
        yield_id: yieldDetails?._id,
      };
      let developerRecords, sponsorRecords;
      const entitlementRecordData = await getPreEntitlementRecords(
        entitlementPayload
      );

      // Sort entitlementRecordData in descending order based on the 'created' time field
      const sortedEntitlementRecordData = entitlementRecordData?.data
        ?.slice()
        .sort((a, b) => {
          const dateA = new Date(a.created.$date);
          const dateB = new Date(b.created.$date);
          return dateB - dateA;
        });
      // Project Sponsor loggedin
      if (userid === projectSponsorOrgId) {
        developerRecords = sortedEntitlementRecordData?.filter(
          (record) =>
            record?.creator &&
            record?.owner_of_entitlement_records === record?.creator
        );
        sponsorRecords = sortedEntitlementRecordData?.filter(
          (record) => record?.owner_of_entitlement_records === userid
        );
      }
      // Project Developer loggedin
      else {
        developerRecords = sortedEntitlementRecordData?.filter(
          (record) => record?.owner_of_entitlement_records === userid
        );
        sponsorRecords = sortedEntitlementRecordData?.filter(
          (record) =>
            record?.owner_of_entitlement_records === projectSponsorOrgId
        );
      }
      yieldDetails["sponsorAmount"] =
        sponsorPercentage -
        (sponsorRecords?.find((record) => record?.no_of_entitlement_record)
          ?.no_of_entitlement_record || 0);
      yieldDetails["developerAmount"] =
        developerPercentage -
        (developerRecords?.find((record) => record?.no_of_entitlement_record)
          ?.no_of_entitlement_record || 0);
      yieldDetails["safeguard_type"] = safeguardType;
      yieldDetails["safeguard_ratio"] = safeguardRatio;
      yieldDetailsToBc.push(yieldDetails);
    }

    if (
      !(await createEntitlementRecordsActionInBC({
        yields: yieldDetailsToBc,
        precreditPercentage: totalPreCredits,
        sponsorPreCreditsAvailability: sponsorPreCreditsAvailability,
        entitlementRecordDetails: entitlementRecordDetails,
        sponsorOrgId: projectSponsorOrgId || defaultSponsorAddress,
        flag: flag,
      }))
    )
      return;

    if (
      postUpdateCreateEntitilementRecords &&
      postUpdateCreateEntitilementRecords !== null &&
      postUpdateCreateEntitilementRecords !== undefined
    ) {
      postUpdateCreateEntitilementRecords();
    }
  } catch {
  } finally {
    refetchYields();
    setSpinningYield(false);
  }
}

async function createTradableCertificatesUtil(
  inputInterventions,
  noOfEntitlementRecords,
  inputYields,
  setSpinningYield,
  createTradableCertificateActionInBC,
  updateYieldBatch,
  userid,
  postUpdateTradableCertificatesRecords
) {
  try {
    setSpinningYield(true);

    //get the Final Certificate Json Meta Data
    const finalcertificateJsonPreviewData = await getFinalCertificateJsonData(
      inputInterventions,
      inputYields
    );

    // //Post the meta data to Ipfs get the URI
    const FinalCertificate_MetadataUri = await postFinalCertificateAttributesToIpfs(
      finalcertificateJsonPreviewData
    );

    // //return if it fails in blockchain
    if (
      !(await createTradableCertificateActionInBC({
        yields: inputYields,
        type: finalcertificateJsonPreviewData?.data?.[0]?.intervention_type,
        amounts: noOfEntitlementRecords,
        status: "active",
        metadata_uri: FinalCertificate_MetadataUri,
        finalCertificateJsonData: JSON.stringify(
          finalcertificateJsonPreviewData
        ),
        userid: userid,
      }))
    )
      return false;
  } catch (error) {
    console.log("error", error);
  } finally {
    setSpinningYield(false);
  }
}

const getFinalCertificateJsonData = async (interventionIds, inputYields) => {
  const finalCertificateData = [];
  const interventionIdsArray = Array.from(interventionIds);
  for (let j = 0; j < interventionIdsArray.length; j++) {
    const intervention_id = interventionIdsArray[j];

    //Intervention Level Information
    const {
      data: interventionDataResponse,
      isLoading: isInterventionLoading,
    } = await store.dispatch(
      interventionApi.endpoints.getInterventionCertificates.initiate({
        intervention_id: intervention_id,
      })
    );

    if (!isInterventionLoading) {
      var interventionData = interventionDataResponse?.data?.find(
        (record) => record?._id === intervention_id
      );
    }

    const yieldUserIDsByAssigneeType = {};
    const assigneeTypesToCheck = ["Project Sponsor", "Validator", "Verifier"];

    // Loop through inputYields to fetch assignment details for each yield_id
    for (let i = 0; i < inputYields?.length; i++) {
      const yield_id = inputYields[i]?.yield_details?._id;

      if (yield_id) {
        const {
          data: yieldAssignmentDetails,
          isLoading: isYieldAssignmentDetailsLoading,
        } = await store.dispatch(
          assignmentApi.endpoints.getAssignmentByParam.initiate({ yield_id })
        );

        if (!isYieldAssignmentDetailsLoading && yieldAssignmentDetails) {
          // Iterate through each assignment object in the response
          yieldAssignmentDetails?.forEach((assignmentObj) => {
            if (
              assigneeTypesToCheck.includes(assignmentObj?.assignee_type) &&
              assignmentObj?.asset_id === null &&
              assignmentObj?.intervention_id === null
            ) {
              const assigneeType = assignmentObj?.assignee_type;
              const orgId = assignmentObj?.organization;

              if (!yieldUserIDsByAssigneeType[assigneeType]) {
                yieldUserIDsByAssigneeType[assigneeType] = new Set();
              }

              // Add unique organization ID
              yieldUserIDsByAssigneeType[assigneeType].add(orgId);
            }
          });
        }
      }
    }

    for (const assigneeType in yieldUserIDsByAssigneeType) {
      yieldUserIDsByAssigneeType[assigneeType] = Array?.from(
        yieldUserIDsByAssigneeType[assigneeType]
      );
    }

    const interv_methodname = interventionData?.method_id;

    const { data: interventionMethod } = await store.dispatch(
      methodsApi.endpoints.getMethod.initiate(interv_methodname)
    );

    var operatorDetails = await getUser(interventionData?.creator);
    // Fetch organization details and convert them to lists
    const projectSponsorDetails = await Promise.all(
      (yieldUserIDsByAssigneeType["Project Sponsor"] ?? []).map((orgId) =>
        getOrganization(orgId)
      )
    );
    const validatorDetails = await Promise.all(
      (yieldUserIDsByAssigneeType["Validator"] ?? []).map((orgId) =>
        getOrganization(orgId)
      )
    );
    const verifierDetails = await Promise.all(
      (yieldUserIDsByAssigneeType["Verifier"] ?? []).map((orgId) =>
        getOrganization(orgId)
      )
    );

    // Join organization names
    const projectSponsorNames = projectSponsorDetails
      .map((detail) => detail?.name)
      .filter(Boolean)
      .join(", ");
    const validatorNames = validatorDetails
      .map((detail) => detail?.name)
      .filter(Boolean)
      .join(", ");
    const verifierNames = verifierDetails
      .map((detail) => detail?.name)
      .filter(Boolean)
      .join(", ");

    const assetIdsList = [];
    for (var i = 0; i < inputYields?.length; i++) {
      if (inputYields[i]?.intervention_details?._id === intervention_id) {
        assetIdsList.push(inputYields[i]?.yield_details?.asset_id);
      }
    }
    const assetsList = await getAssetsByIds(assetIdsList);

    const assetsData = {};
    const assetsArray = [];
    let sumOfEntitlementRecords = 0;
    for (let i = 0; i < inputYields?.length; i++) {
      if (inputYields[i]?.intervention_details?._id === intervention_id) {
        const yieldObject = {
          yield_period: inputYields[i]?.yield_details?.period,
          yield_storage_duration:
            inputYields[i]?.yield_details?.storage_duration,
          yield_co2_realized:
            inputYields[i]?.updated_no_of_entitlement_record ??
            inputYields[i]?.no_of_entitlement_record,
        };
        let assetObject = assetsData[inputYields[i]?.yield_details?.asset_id];
        if (!assetObject) {
          const assetDetails = assetsList.find(
            (asset) => asset._id === inputYields[i]?.yield_details?.asset_id
          );
          assetObject = {
            asset_name: assetDetails?.name,
            asset_parameters: assetDetails?.param,
            asset_co2_emission_equivalent:
              assetDetails?.co2_emission_equivalent,
            asset_equivalent_calulation_method:
              assetDetails?.equivalent_calculation_method,
            yields: [],
          };
          assetsData[inputYields[i]?.yield_details?.asset_id] = assetObject;
          assetsArray.push(assetObject);
        }
        assetObject.yields.push(yieldObject);
      }

      sumOfEntitlementRecords =
        sumOfEntitlementRecords +
        (inputYields[i]?.updated_no_of_entitlement_record !== undefined
          ? Number(inputYields[i]?.updated_no_of_entitlement_record)
          : Number(inputYields[i]?.no_of_entitlement_record));
    }

    const finalCertificateJSonData = {
      intervention_type: interventionData?.type,
      intervention_name: interventionData?.name,
      intervention_project_id: interventionData?.project_id,
      intervention_method: interventionMethod?.[0]?.name,
      intervention_standards_used: interventionData?.standards_used,
      intervention_summary: interventionData?.ghg_project_summary,
      intervention_region: interventionData?.region,
      intervention_crediting_period: interventionData?.crediting_period,
      intervention_project_period: interventionData?.project_period,
      intervention_operator: operatorDetails?.org?.name
        ? operatorDetails?.org?.name
        : "",
      intervention_project_sponsor: projectSponsorNames,
      intervention_validator_of_quality: validatorNames,
      intervention_verifier_of_quality: verifierNames,
      amount_of_tradable_certificates: sumOfEntitlementRecords,
      assets: assetsArray,
    };
    finalCertificateData.push(finalCertificateJSonData);
  }

  return { data: finalCertificateData };
};

export {
  createEntitlementRecordsUtil,
  createTradableCertificatesUtil,
  getAssetDetails,
  makeAvailableForQualityVerifier,
};

import { React, useState } from "react";
import { useSelector } from "react-redux";
import {
  useAddDocTypeMutation,
  useDeleteDocTypeMutation,
  useGetDocTypesQuery,
  useUpdateDocTypeMutation,
} from "../../store/ds/doctypeapi";

import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message,
  Radio,
} from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { DisplayUserName } from "../Utils/DisplayUserName";

function DocType() {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const userId = useSelector((state) => state?.auth?.userid);

  const columns = [
    {
      key: "doctype",
      title: "Document Name",
      dataIndex: "doctype",
    },
    {
      key: "desc",
      title: "Description",
      dataIndex: "desc",
    },
    {
      key: "visibility",
      title: "Default Visibility",
      dataIndex: "visibility",
      render: (visibility) => {
        return visibility === "PUBLIC"
          ? "Public"
          : visibility === "PRIVATE"
          ? "Private"
          : "";
      },
    },
    {
      key: "overwriteOption",
      title: "Overwrite Document",
      dataIndex: "overwriteOption",
      render: (overwriteOption) => {
        return overwriteOption === true
          ? "Yes"
          : overwriteOption === false
          ? "No"
          : "";
      },
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: "creator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },
    {
      key: "updator",
      title: "Updator",
      dataIndex: "updator",
      render: (record) => {
        return <DisplayUserName userid={record} place="table" />;
      },
    },

    {
      key: "CreatedAt",
      title: "Created",
      dataIndex: "CreatedAt",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },
    // {
    //   key: "UpdatedAt",
    //   title: "Updated",
    //   dataIndex: "UpdatedAt",
    //   render: (record) => {
    //     return (
    //       <span>
    //         {record
    //           ? moment
    //               .utc(record)
    //               .local()
    //               .format("DD MMM, YYYY @ hh:mm:ss")
    //           : undefined}
    //       </span>
    //     );
    //   },
    // },
    {
      key: "6",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditRecord(record);
              }}
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical"></Divider>
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 10 }}
            />
          </>
        );
      },
    },
  ];

  const { data = [], isLoading, isFetching, isError } = useGetDocTypesQuery();
  const [addDocType, { isCreateLoading }] = useAddDocTypeMutation();
  const [updateDocType, { isUpdateLoading }] = useUpdateDocTypeMutation();
  const [deleteDocType, { isDeleteLoading }] = useDeleteDocTypeMutation();

  if (!isLoading) {
  }

  const key = "updatable";
  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCreate(false);
  };

  const handleAddRecord = async (record) => {
    try {
      await addDocType({ ...record, creator: userId })
        .unwrap()
        .then((res) => {
          message.success({
            content: "Document Configuration added successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to add Document Configuration!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleEditRecord = async (record) => {
    try {
      await updateDocType({ ...record, updator: userId })
        .unwrap()
        .then((res) => {
          message.success({
            content: "Document Configuration updated successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to update Document Configuration!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteRecord = async (id) => {
    try {
      await deleteDocType(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Document Configuration deleted successfully!",
            key,
            duration: 2,
          });
          console.log(res);
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete Document Configuration!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onCreate = (values) => {
    if (isEditing) {
      editingRecord.doctype = values.doctype;
      editingRecord.desc = values.desc;
      editingRecord.visibility = values.visibility;
      editingRecord.overwriteOption = values.overwriteOption;
      handleEditRecord(editingRecord);
      setIsEditing(false);
    } else if (isCreate) {
      handleAddRecord(values);
    }
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        console.log({ ...record });
        console.log({ ...record }.ID);
        handleDeleteRecord({ ...record }.ID);
      },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2}>Document Configuration</Typography.Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsEditing(false);
              setIsCreate(true);
              setEditingRecord(null);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          <Table size="small" columns={columns} dataSource={data}></Table>
        </Card>
      </div>
      <>
        {isEditing || isCreate ? (
          <DocumentTypeFormModal
            visible={isEditing || isCreate}
            onCreate={onCreate}
            onCancel={() => {
              resetEditing();
            }}
            editingRecord={editingRecord}
          />
        ) : null}
      </>
    </div>
  );
}

const DocumentTypeFormModal = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      width={500}
      title="Add/Edit Document Name"
      open={visible}
      okText="Save"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="Documenttype"
        initialValues={editingRecord}
      >
        <Form.Item
          name="doctype"
          label="Document Name"
          rules={[
            {
              required: true,
              message: "Please input Document Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please input Document Name desc!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="visibility"
          label="Default Visibility"
          rules={[{ required: true, message: "Please select visibility!" }]}
        >
          <Radio.Group>
            <Radio value="PUBLIC">Public</Radio>
            <Radio value="PRIVATE">Private</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="overwriteOption"
          label="Override document?"
          rules={[{ required: true, message: "Please select an option!" }]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocType;
